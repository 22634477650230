<template>
  <a-card :bordered="false" class="card-area">
    <div class="search">
      <!-- 搜索区域 -->
      <div class="items" style="flex: none">
        <div class="one-item">
          <a-input
              class="input"
              v-model="queryParams.keyword"
              placeholder="参数名称"
              allowClear
          />
        </div>
        <div class="one-item" style="padding-left: 10px">
          <a-checkbox
              class="input"
              v-model="hideAuditConfig"
              placeholder="隐藏审核开关参数"
          >隐藏审核开关参数
          </a-checkbox>
        </div>
      </div>
      <div class="operator">
        <a-button type="primary" @click="search(1)">查询</a-button>
        <a-button style="margin-left: 8px" @click="reset">重置</a-button>
        <!--        <a-button v-hasPermission="'cate:add'" type="primary" @click="add" style="margin-left:20px;" icon="plus">-->
        <!--          新增-->
        <!--        </a-button>-->
      </div>
    </div>

    <div>
      <!-- 表格区域 -->
      <a-table :columns="columns"
               rowKey="id"
               bordered
               size="small"
               :dataSource="dataSource2"
               :loading="loading"
               @change="handleTableChange"
               :scroll="{ x: 900 }"
               :pagination="false">
        <template slot="index" slot-scope="text,row,index">
          {{ index + 1 }}
        </template>
        <template slot="common-text" slot-scope="text,row">
          <span :class="{changed:row.modified}">
            {{ text }}
          </span>
        </template>

        <template slot="operation" slot-scope="text, record">
          <a-icon v-hasPermission="'config:edit'" type="edit" theme="twoTone" twoToneColor="#4a9ff5"
                  v-if="record.isvFlag==currentUser.isvId||currentUser.isvId==1"
                  @click="edit(record)" title="修改"></a-icon>
        </template>
      </a-table>
    </div>
    <a-modal v-drag-modal
             v-model="configEditVisible"
             :centered="true"
             :maskClosable="false"
             :keyboard="true"
             :width="850"
             @cancel="configEditVisible=false"
             @ok="updateConfig"
             title="修改系统参数">
      <a-alert type="warning" show-icon message="系统参数配置确定后，请不要频繁修改！"/>
      <div style="padding: 40px 20px;">
        <label>{{ editConfig.remark }}：</label>
        <a-switch v-if="editConfig.valueType === 'switch' " v-model="editConfig.value"></a-switch>
        <a-input-number v-else-if="editConfig.valueType === 'number' " v-model="editConfig.value"></a-input-number>
        <a-textarea v-else-if="editConfig.valueType === 'text' " v-model.trim="editConfig.value" :allowClear="true"
                    :autoSize="{ minRows: 20, maxRows: 20 }"
        ></a-textarea>
        <a-input v-else v-model.trim="editConfig.value" :allowClear="true"></a-input>
      </div>
    </a-modal>
  </a-card>
</template>

<script>
import {mapState} from "vuex";
// import {getBase64OfPreview} from '@/utils/common.js';
import {ListMixin} from "@/mixin/list-mixin";

export default {
  name: 'Config',
  mixins: [ListMixin],
  data() {
    return {
      listGetUrl: "sysConfig/list",
      listMethod: "get",
      hideAuditConfig: false,
      configEditVisible: false,
      editConfig: {},
    }
  },
  computed: {
    ...mapState({
      currentUser: state => state.account.user
    }),
    dataSource2() {
      return this.hideAuditConfig ? this.dataSource.filter(v => v.key.indexOf("ab-") != 0) : this.dataSource;
    },
    columns() {
      return [{
        title: '序号',
        width: 80,
        align: 'center',
        scopedSlots: {customRender: 'index'}
      }, {
        title: '说明',
        dataIndex: 'remark',
        width: 400,
        scopedSlots: {customRender: 'common-text'}
      },
        {
          title: '分组',
          dataIndex: 'group',
          width: 250,
          scopedSlots: {customRender: 'common-text'}
        },
        {
          title: '名称',
          dataIndex: 'key',
          width: 250,
          scopedSlots: {customRender: 'common-text'}
        },
        {
          title: '值类型',
          dataIndex: 'valueType',
          width: 80,
          align: 'center',
        },
        {
          title: '配置项值',
          dataIndex: 'value',
          width: 250,
          align: 'center',
        }, {
          title: '修改时间',
          dataIndex: 'lastUpdated',
          align: 'center',
          width: 200,
        }, {
          title: '操作',
          align: 'center',
          dataIndex: 'operation',
          width: 80,
          scopedSlots: {customRender: 'operation'},
        },
        {
          title: '',
          align: 'center',
          dataIndex: 'ddd',
        }]
    }
  },
  mounted() {
    this.search(1);
  },
  methods: {
    edit(record) {
      this.configEditVisible = true
      this.editConfig = Object.assign({}, record)
    },
    updateConfig() {
      this.$postJson('sysConfig/update', this.editConfig).then((r) => {
        this.search()
        this.configEditVisible = false
        this.$message.success('系统参数修改成功')
      })
    }
  }
}
</script>

<style lang="less" scoped>
//@import "../../../../static/less/Common";

.changed {
  color: #FF9900;
}
</style>
